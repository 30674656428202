<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <div class="water-marks" v-if="openViewWatermark">
      <div class="item" v-for="i in 60" :key="i">
        <OpenData type="userName" :openid="userInfo.realName" />({{
          userInfo.account
        }})
        <OpenData type="departmentName" :openid="userInfo.departmentName" />
      </div>
    </div>
    <iframe
      frameborder="0"
      marginheight="0"
      framespacing="0"
      marginwidth="0"
      width="100%"
      height="100%"
      :src="filePath"
    ></iframe>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ls from "@/utils/local-storage";
import OpenData from "@/components/OpenData.vue";

export default defineComponent({
  components: {
    OpenData,
  },
  props: {
    openViewWatermark: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: "dialog",
    },
    filePath: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      userInfo: ls.get("userInfo"),
    };
  },
});
</script>
<style lang="less" scoped>
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  position: relative;
  &.page {
    height: calc(100vh - 262px);
  }
  .pic {
    max-width: 100%;
  }
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 42px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 50px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
</style>
