<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <div class="audio-box">
      <div class="water-mark" v-if="openViewWatermark">
        <OpenData type="userName" :openid="userInfo.realName" />({{
          userInfo.account
        }})
        <OpenData type="departmentName" :openid="userInfo.departmentName" />
      </div>
      <div class="bg"></div>
      <audio class="audio" controls :src="filePath" controlslist="nodownload" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ls from "@/utils/local-storage";
import OpenData from "@/components/OpenData.vue";

export default defineComponent({
  components: {
    OpenData,
  },
  props: {
    openViewWatermark: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: "dialog",
    },
    filePath: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      userInfo: ls.get("userInfo"),
    };
  },
});
</script>
<style lang="less" scoped>
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  text-align: center;
  &.page {
    height: calc(100vh - 262px);
  }
  .audio-box {
    width: 100%;
    max-width: 960px;
    height: 600px;
    position: relative;
    background-image: url(../../assets/image/audio-cover.gif);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    margin: 0 auto;
    .bg,
    .audio {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 54px;
      background: #cfcfcf;
      &::-webkit-media-controls-panel {
        background: #cfcfcf;
        height: 54px;
        width: 100%;
        border-radius: 0 !important;
      }
    }
  }
}
.water-mark {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  color: rgba(170, 170, 170, 0.4);
  line-height: 40px;
}
</style>
